import React from 'react'
import { Link } from 'gatsby'

import Layout from '../../components/layout'

class EnrollmentAccepted extends React.Component {
  render() {
    return (
      <Layout location={this.props.location} customClass="contacts">
        <header>
          <h1>Děkujeme za váš zájem.</h1>
        </header>
        <div className="page-content">
          <p>Vaši přihlášku jsme přijali.</p>
          <Link className="Link--button" to="/">
            Pokračovat na hlavní stranu
          </Link>
        </div>
      </Layout>
    )
  }
}

export default EnrollmentAccepted
